<template>
  <div class="d-flex flex-row h-100">
    <iframe
      class="flex-grow-1"
      :src="pdfSrc"
      frameborder="0"
      style="height: 100vh; width: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {

  data() {
    const documentPath = localStorage.currentDocumentView;

    return {
      pdfSrc: documentPath
    }
  },
  mounted() {
    if (this.pdfSrc.startsWith('http:')) {
      this.pdfSrc = this.pdfSrc.replace('http:', 'https:');
    }
  },
};
</script>

<style lang="scss" scoped></style>
